function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
		.replace(/-/g, '+')
		.replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (var i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
};

const object = {
	dictKey : "dict://",
	reCaptcha : "",
	localstorageKey : "medifraudesv1",
	endpoints: Object.freeze({
		auth : 'auth',
		users : 'users',
		log: 'log',
		permisos: 'permisos',
		rolesxpermisos: 'rolesxpermisos',
		registros: 'registros',
		medidores: 'medidores',
		fraudes: 'fraudes',
		roles: 'roles'
	}),
	dictionaryIndexes : Object.freeze({
		users : 15,
		log: 9999,
		permisos: 9999,
		rolesxpermisos: 9999,
		registros: 5,
		medidores: 15,
		fraudes: 15,
		roles: 15,

	}),
	VAPIDPublicKey : urlBase64ToUint8Array('BP2AliyxfTyuw6q_be_AdScBhjZN8USyE3V8QPHV3OKnjHARAKuXAboYfY5_yDL-CyBUtZik73sHuTGo3ELOa-I')
};

switch(process.env.NODE_ENV){
	case 'production':
		object.apiURL = "https://medidores2.sistemas.nixel.com.ar/api/v1/";
		object.uploadsURL = "/uploads/";
		break;
	default:
		object.apiURL = "http://localhost/medifraudes/v1/";
		object.uploadsURL = "http://localhost/medifraudes/uploads/";
		break;
}

export default object;
